import React from 'react';

import '../css/CarruselProductos.css';

const ThumbnailList = ({ images = [], selectedImage, setSelectedImage, setOnError }) => {
  return (
    images?.slice()
      .sort((a, b) => a.orden - b.orden)
      .map((img, index) => (
        <img
          className='thumbnail-image no-select'
          key={index}
          src={img.path_thumbnail}
          alt={`Thumbnail ${index + 1}`}
          onClick={() => setSelectedImage(img)}
          onError={() => setOnError(true)}
          style={{ border: img.path === selectedImage.path ? '2px solid #FF6C00' : '0.5px solid gray'}}
        />
      )
    )     
  );
};

export default ThumbnailList;