import React from 'react'
import { Modal, styled } from "@material-ui/core";
import Container from '../../../../components/controls/Container';
import IconClose from '../../../../components/icons/IconClose';
import CardPromoCondi from '../CardPromoCondi';
import IconArrowForward from '../../../../components/icons/IconArrowForwad';
import ButtonWithIcon from '../../../../components/controls/ButtonWithIcon';
import Swal from '../../../../shared/SweetAlert';
import { useState } from 'react';

const ModalPromoCondi = ({ productos, onAddPromo, open, onClose }) => {

    //state
    const [loader, setLoader] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);

    //methods
    const handleProductSelection = (productCode) => {
        let filterProductos = selectedProducts.filter(code => code === productCode);

        if (filterProductos.length > 0) {
             setSelectedProducts(selectedProducts.filter(code => code !== productCode));
        } else {
            setSelectedProducts([...selectedProducts, productCode]);
        }
    }
    
    const handleAddProductoEnPromoCondi = async () => {
        setLoader(true);

        for await(const codigo of selectedProducts) {
            try {
                await onAddPromo(codigo, 1);
            } 
            catch(err) {
                Swal.fire({
                    title: 'Error',
                    text: err,
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: 'Orange',
                })
            }
        }

        setSelectedProducts([])
        setLoader(false);
        onClose(false);
    }

    const Style = {
        Icon: {
            position: 'absolute',
            right: '20px',
            top: '18px'
        }
    }

  return (
    <Modal
        open={open}
        onClose={() => onClose(false)}
        BackdropProps={{
            style: { pointerEvents: "none" }
        }}
    >
        <Container>
            <Box>
                <IconClose style={Style.Icon} width={23.75} height={24} fill={"black"} onClick={onClose}/>
                <Title>Promoción</Title>
                <Descripcion>El producto que acabas de agregar tiene en promoción los siguientes artículos, selecciona los productos que desees agregar, luego presiona el botón de continuar.</Descripcion>
                <Content>
                    {
                        productos.map(producto => {
                            return <CardPromoCondi producto={producto} addProducto={handleProductSelection}/>
                        })
                    }

                </Content>
                <ButtonWithIcon
                    icon={<IconArrowForward width={20} height={20} fill={'#ffff'} />}
                    iconSize={18}
                    text={'Continuar'}
                    textSize={13}
                    textWeight={500}
                    height={36}
                    loading={loader}
                    onClick={handleAddProductoEnPromoCondi}
                    flexDirection={'row-reverse'}
                    className='buttonWithIcon-orange'
                    aria-label={'Ver productos similares'}
                />
            </Box>
        </Container>
    </Modal>
  )
}

const Box = styled('div')({
    backgroundColor: 'white',
    margin: '0 auto',
    maxWidth: '600px',
    borderRadius: '25px',
    padding: '30px',
    marginTop: '5vh',
    position: 'relative',
});

const Title = styled('h3')({
    fontWeight: '600',
    fontSize: '24px',
    fontFamily: 'Inter',
    margin: '0px',
    padding: '0px',
    marginBottom: '8px',
    '@media (max-width: 500px)': {
        fontSize: '18px'
    }
});

const Descripcion = styled('span')({
    color: 'rgba(34, 34, 34, 0.65)',
    fontWeight: '400',
    fontSize: '14px',
    '@media (max-width: 500px)': {
        fontSize: '12px'
    }
});

const Content = styled('div')({
    border: 'solid 1px rgba(237, 237, 237, 1)',
    maxHeight: '45vh',
    borderRadius: '20px 0px 0px 20px',
    margin: '15px 0px',
    padding: '15px',
    overflowY: 'scroll',
});

export default ModalPromoCondi;