
// Validación de name
export const nameValidator = (name) => {
  if (!name) throw new Error('El nombre es obligatorio');
  if (!/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s'-]+$/.test(name)) throw new Error('El nombre solo puede contener letras, espacios y caracteres válidos');
};

// Validación de teléfono
export const phoneValidator = (tel) => {
  if (tel && !/^\d{8,11}$/.test(tel)) throw new Error('El teléfono debe contener entre 8 y 11 dígitos numéricos');
};

// Validación de email
export const emailValidator = (email) => {
  if (!email) throw new Error('El correo electrónico es obligatorio');
  if (!/\S+@\S+\.\S+/.test(email)) throw new Error('El correo electrónico no es válido');
};

// Validación de nit
export const nitValidator = (nit) => {
  if (!nit) throw new Error('El NIT es obligatorio');  
  const nitRegex = /^\d{1,12}-?\d?$/;
  if (!nitRegex.test(nit)) throw new Error('El NIT solo puede tener valores numéricos, con o sin un guion');
};

// Validación de address
export const addressValidator = (address) => {
  if (address && ((address.length < 8) || (address.length > 250))) throw new Error('La dirección debe tener entre 8 y 50 caracteres');
};

// Validación de fecha de nacimiento
export const birthDateValidator = (birthDate) => {
  if (!birthDate) throw new Error('La fecha de nacimiento es obligatoria');
  const birthDateObject = new Date(birthDate);
  const today = new Date();
  let age = today.getFullYear() - birthDateObject.getFullYear();
  const monthDifference = today.getMonth() - birthDateObject.getMonth();

  // Ajuste de edad por cumple
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDateObject.getDate())) age--;
  if (age < 18) throw new Error('Debes ser mayor de edad');
  if (!/^\d{4}-\d{2}-\d{2}$/.test(birthDate)) throw new Error('La fecha de nacimiento debe tener formato YYYY-MM-DD');
};

// Validación de estado civil
export const maritalStatusValidator = (status) => {
  if (status && !/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]+$/.test(status)) {
    throw new Error('El estado civil solo puede contener letras y espacios');
  }
};

// Validación de experiencia laboral
export const workExperienceValidator = (experience) => {
  if (experience && (experience.length < 5 || experience.length > 500)) {
    throw new Error('La experiencia laboral debe tener entre 5 y 500 caracteres');
  }
};

// Validación de plaza de interés
export const jobInterestValidator = (job) => {
  if (!job) throw new Error('La plaza de interés es obligatoria');
  if (!/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]+$/.test(job)) {
    throw new Error('La plaza de interés solo puede contener letras y espacios');
  }
  if (job.length < 3 || job.length > 50) {
    throw new Error('La plaza de interés debe tener entre 3 y 50 caracteres');
  }
};

// Validación de profesión
export const professionValidator = (profession) => {
  if (profession && !/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]+$/.test(profession)) throw new Error('La profesión solo puede contener letras y espacios');
  if (profession && ((profession.length < 3) || (profession.length > 100))) throw new Error('La profesión debe tener entre 3 y 50 caracteres');
};

// Validación de deuda
export const debtValidator = (debt) => {
  if (debt && (debt.length < 3 || debt.length > 250)) throw new Error('La descripción de la deuda debe tener entre 3 y 250 caracteres');
};

// Validación de vehículo
export const vehicleValidator = (vehicle) => {
  if (vehicle && !/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]+$/.test(vehicle)) throw new Error('El vehículo solo puede contener letras y espacios');
};

// Validación de licencia
export const licenseValidator = (license) => {
  if (license && !/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]+$/.test(license)) throw new Error('La licencia solo puede contener letras y espacios');
};

// Validación de pretensión salarial
export const salaryExpectationValidator = (salary) => {
  if (typeof salary !== 'number' || salary < 0 || salary > 100000) throw new Error('La pretensión salarial debe ser un número entre 0 y 100,000.00');
};

// Validación de estudiante
export const studentValidator = (isStudent) => {
  if (typeof isStudent !== 'boolean') throw new Error('El valor de estudiante debe ser verdadero o falso');
};

// Validación de archivos
export const fileValidator = (file, types) => {
  if (!file) throw new Error('El archivo adjunto es obligatorio');
  const fileExtension = file.name.split('.').pop().toLowerCase();
  if (!types.includes(fileExtension)) throw new Error(`El tipo de archivo no es válido.  Se acepta: ${types.join(', ')}`);
  const maxFileSize = 5 * 1024 * 1024;
  if (file.size > maxFileSize) throw new Error('El archivo debe ocupar menos de 5MB');
};

// Validación de caracteres no permitidos
export const emojiValidator = (input) => {
  const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
  if (input && emojiRegex.test(input)) throw new Error('El texto contiene valores desconocidos');
};

// Validación de caracteres no permitidos
export const empresaPuestoValidator = (input) => {
  if (!input) throw new Error('Los datos de la empresa son obligatorios');
  const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
  if (input && emojiRegex.test(input)) throw new Error('Los datos de la empresa contienen valores desconocidos');
};

// Validación de fecha de inicio
export const startDateValidator = (startDate) => {
  if (!startDate) throw new Error('La fecha de inicio es obligatoria');
  const startDateObject = new Date(startDate);
  const today = new Date();  
  today.setHours(0, 0, 0, 0);
  
  if (startDateObject >= today) throw new Error('La fecha de inicio debe ser anterior al día actual');
};

// Validación de fecha de fin
export const endDateValidator = (endDate, startDate) => {
  if (!endDate) throw new Error('La fecha de fin es obligatoria');
  if (!startDate) throw new Error('La fecha de inicio es obligatoria');
  const endDateObject = new Date(endDate);
  const startDateObject = new Date(startDate);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (endDateObject >= today) throw new Error('La fecha de fin debe ser anterior al día actual');
  if (endDateObject <= startDateObject) throw new Error('La fecha de fin debe ser posterior a la fecha de inicio');
};

// Validación de saldo deuda
export const debtAmountValidator = (salary) => {
  if (!salary) throw new Error('El saldo pendiente es obligatorio');
  if (typeof salary !== 'number' || salary < 0) throw new Error('La pretensión salarial debe ser un número mayor a 0');
};

// Validación de banco
export const bankValidator = (name) => {
  if (!name) throw new Error('Los datos del banco son obligatorios');
  if (!/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s'-]+$/.test(name)) throw new Error('El banco solo puede contener letras, espacios y caracteres válidos');
};