import { useMediaQuery } from "@material-ui/core";
import { useState, useEffect } from 'react';
import React from 'react';
import useTitle from '../../shared/hooks/useTitle';
import LoadCVFile from "./components/LoadCVFile";
import SubmitButton from "./components/SubmitButton";
import DatosLaborales from "./components/DatosLaborales";
import DatosPersonales from "./components/DatosPersonales";
import empleadoBannerMovil from '../../pages/assets/empleos/empleos-banner-movil_v2.png';
import empleadoBannerDesktop from '../../pages/assets/empleos/empleos-banner_desktop_v2.png';
import InformacionFinanciera from "./components/InformacionFinanciera";

import './style.css';

const Reclutamientopage = () => {

  useTitle("Reclutamiento Intelaf");
  const matches = useMediaQuery('(min-width: 600px)');

  const initialFormData = {
    NombreCompleto: '',
    Telefono: '',
    CorreoElectronico: '',
    NIT: '',
    DireccionActual: '',
    FechaNacimiento: '',
    EstadoCivil: '',
    ExperienciaLaboral1: '',
    ExperienciaLaboral2: '',
    ExperienciaLaboral3: '',
    PlazaAplicada: '',
    Profesion: '',
    Deuda1: '',
    Deuda2: '',
    Deuda3: '',
    Vehiculo: 'No tengo',
    Licencia: false,
    TipoLicencia: 'No tengo',
    PretensionSalarial: '',
    EstudiaActualmente: false,
    cv: null,
  }

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
    if (value === 'No tengo') setFormData((prev) => ({...prev, Licencia: false}));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData((prev) => ({ ...prev, cv: file }));
  };

  const handleChangeCheckbox = (e) => {
    const { id, checked } = e.target;
    setFormData((prev) => ({ ...prev, [id]: checked }));
  };

  const clearFormData = () => {
    setFormData(initialFormData);
  };

  return (
    <div className="container container-reclutamiento-page">
      <img src={ matches ?  empleadoBannerDesktop : empleadoBannerMovil } alt="Banner empleos" fetchpriority="high"></img>
      <br/>
      <br/>
      <h1>Reclutamiento Intelaf</h1>
      <div className="subtitulo-formulario-reclutamiento">
        <hr/>
        <h3><b>Creemos en tu capacidad</b></h3>
        <p>¡Sé parte del equipo <b>Intelaf</b>! Completa tu hoja de vida y descubre tu próximo desafío profesional.</p>
      </div>
      <DatosPersonales
        formData={formData}
        handleChange={handleChange}
      />
      <DatosLaborales
        formData={formData}
        handleChange={handleChange}
        setFormData={setFormData}
      />
      <InformacionFinanciera 
        formData={formData}
        handleChange={handleChange}
        handleChangeCheckbox={handleChangeCheckbox}
        setFormData={setFormData}
      />
      <LoadCVFile
        handleFileChange={handleFileChange}
      />
      <SubmitButton formData={formData} clearFormData={clearFormData} />
      <hr />
    </div>
  );
};

export default Reclutamientopage;