import React from 'react';
import TextBox from '../../../components/controls/TextBox';

import './../style.css';

const LoadCVFile = ({ handleFileChange}) => {

  return (      
    <div className="formulario-reclutamiento">
        <div>
          <b>Curriculum Vitae *</b>
          <hr/>
        </div>
        <div className="formulario-fila-col-1"> 
          <TextBox
            id="cv"
            type='file'
            accept='.pdf'
            onChange={handleFileChange}
            tabIndex={0}
          />
        </div>
      </div>
  );
};

export default LoadCVFile;