import { setScroll } from '../state/generalSlice';
import { setProducts } from '../state/productosSlice';
import { useSelector, useDispatch } from 'react-redux';
import { resetCheckout, updateCheckout } from '../state/checkOutSlice';
import { resetCarrito, updateCarrito, createCarrito } from '../state/carritoSlice';

import FleteDto from '../../models/fleteDto';

import { 
  postCreateCarritoAsync, 
  postAddProductoCarritoAsync, 
  getShowCarritoAsync, 
  putUpdateProductoCarritoAsync, 
  deleteProductoCarritoAsync,
  putLimpiarCarritoAsync,
  postAddCodigoDescuentoAsync,
  deleteCodigoDescuentoAsync
} from '../../services/intelafApiClient/repository/carritoRepository';

import {
  postAddDireccionAsync,
  putUpdateDireccionAsync
} from '../../services/intelafApiClient/repository/direccionRepository';

import {
  postShowFlete
} from '../../services/intelafApiClient/repository/fleteRepository'
import funcionalArea from '../../enums/funcionalArea';
import MetodoEnvio from '../../enums/metodoDeEnvio';
import MetodoPago from '../../enums/metodoDePago';
import FormaDePago from '../../enums/FormasDePago';
import metodoDePago from '../../enums/metodoDePago';
import { store } from '../store';

const useCheckOutHandler = () => {

  //Redux
  const dispatch = useDispatch();
  const checkout = useSelector((store) => store.checkout);
  const carrito  = useSelector((store) => store.carrito);

  //methods
  const handleResetCarritoCheckOut = () => {
    /* Limpiando lista de productos */
    dispatch(setScroll({title: '', position: 0}));
    dispatch(setProducts([]));

    dispatch(resetCarrito());
    dispatch(resetCheckout());
    window.scrollTo(0, 0);
  }

  const handleGetState = () => {
    return store.getState().checkout;
  }

  // Actualizar Crear carrito
  const handleCreateCarritoCheckOutAsync = async (sucursalDto, metodoDeEnvio, producto = null) => {
    let carritoCreated = await postCreateCarritoAsync(sucursalDto.CodigoSucursal);
    await dispatch(createCarrito(carritoCreated.toJSON()));

    let checkoutUpdate = { SucursalDeEntrega: sucursalDto, MetodoDeEnvio: metodoDeEnvio }
    await dispatch(updateCheckout(checkoutUpdate));

    if(producto !== null) {
      let carritoNew = await postAddProductoCarritoAsync(carritoCreated.idCarrito, producto, 1, checkout.MetodoDePago);
      await dispatch(updateCarrito(carritoNew.toJSON()));
    }
  }

  // Actualizar Sucursal de envio
  const handleUpdateSucursalCarritoCheckOutAsync = async (sucursalDto, metodoDeEnvio) => {
    let productos  = [ ...carrito.Items, ...carrito.ItemsNoDisponibles ];

    let carritoCreated = await postCreateCarritoAsync(sucursalDto.CodigoSucursal);
    await dispatch(createCarrito(carritoCreated.toJSON()));

    let checkoutUpdate = { SucursalDeEntrega: sucursalDto, MetodoDeEnvio: metodoDeEnvio }
    await dispatch(updateCheckout(checkoutUpdate));

    if(productos.length > 0) {
      let itemsNoDisponibles = [];
      let carritoNew         = {};

      if(metodoDeEnvio === MetodoEnvio.DOMICILIO) {
        for (const item of productos) {
          try {
            carritoNew = await postAddProductoCarritoAsync(carritoCreated.idCarrito, item.CodigoInventario, item.Cantidad, checkout.MetodoDePago);
          } catch(err) {
            if(!itemsNoDisponibles.some(i => i.CodigoInventario === item.CodigoInventario)) {
              itemsNoDisponibles.push(item)
            } 
          }
        }

      } else {

        let filterProductos = productos.filter(p => p.Area.toUpperCase() !== funcionalArea.FLETES);
        for (const item of filterProductos) {
          try {
            carritoNew = await postAddProductoCarritoAsync(carritoCreated.idCarrito, item.CodigoInventario, item.Cantidad, checkout.MetodoDePago);
          } catch(err) {
            if(!itemsNoDisponibles.some(i => i.CodigoInventario === item.CodigoInventario)) {
              itemsNoDisponibles.push(item)
            } 
          }
        }

        await dispatch(updateCheckout({ DireccionDeEntrega: {}, QuienEntrega: '' }));
      }

      if(Object.keys(carritoNew).length > 0) {
        carritoNew.ItemsNoDisponibles = itemsNoDisponibles;
        await dispatch(updateCarrito(carritoNew.toJSON()));
      } else {
        carritoCreated.ItemsNoDisponibles = itemsNoDisponibles;
        await dispatch(updateCarrito(carritoCreated.toJSON()));
      }
    }
  }

    // Actualizar Sucursal de envio
  const handleUpdateSucursalProductoCheckOutAsync = async (sucursalDto, metodoDeEnvio, producto = null) => {
    let productos  = [ ...carrito.Items, ...carrito.ItemsNoDisponibles ];

    let carritoCreated = await postCreateCarritoAsync(sucursalDto.CodigoSucursal);
    await dispatch(createCarrito(carritoCreated.toJSON()));

    let checkoutUpdate = { SucursalDeEntrega: sucursalDto, MetodoDeEnvio: metodoDeEnvio }
    await dispatch(updateCheckout(checkoutUpdate));


    
    if(producto) {
      try {
        let  carritoNew = await postAddProductoCarritoAsync(carritoCreated.idCarrito, producto, 1, checkout.MetodoDePago);
        await dispatch(updateCarrito(carritoNew.toJSON()));
               
      } catch(err) {
        console.log(err);
      }
    }

    if(productos.length > 0) {
      let itemsNoDisponibles = [];
      let carritoNew         = {};

      if(metodoDeEnvio === MetodoEnvio.DOMICILIO) {
        for (const item of productos) {
          try {
            carritoNew = await postAddProductoCarritoAsync(carritoCreated.idCarrito, item.CodigoInventario, item.Cantidad, checkout.MetodoDePago);
          } catch(err) {
            if(!itemsNoDisponibles.some(i => i.CodigoInventario === item.CodigoInventario)) {
              itemsNoDisponibles.push(item)
            } 
          }
        }

      } else {

        let filterProductos = productos.filter(p => p.Area.toUpperCase() !== funcionalArea.FLETES);
        for (const item of filterProductos) {
          try {
            carritoNew = await postAddProductoCarritoAsync(carritoCreated.idCarrito, item.CodigoInventario, item.Cantidad, checkout.MetodoDePago);
          } catch(err) {
            if(!itemsNoDisponibles.some(i => i.CodigoInventario === item.CodigoInventario)) {
              itemsNoDisponibles.push(item)
            } 
          }
        }

        await dispatch(updateCheckout({ DireccionDeEntrega: {}, QuienEntrega: '' }));
      }

      if(Object.keys(carritoNew).length > 0) {
        carritoNew.ItemsNoDisponibles = itemsNoDisponibles;
        await dispatch(updateCarrito(carritoNew.toJSON()));
      } else {
        carritoCreated.ItemsNoDisponibles = itemsNoDisponibles;
        await dispatch(updateCarrito(carritoCreated.toJSON()));
      }
    }
  }
  
  //Actualizar metodo pago carrito
  const handleUpdateMetodoDePagoCarritoAsync = async (metodoDePago) => {
    let itemsNoDisponibles = carrito.ItemsNoDisponibles;

    if(metodoDePago !== null && metodoDePago !== undefined && metodoDePago !== '') {
      if(metodoDePago === MetodoPago.MONETARIO) {
        await dispatch(updateCheckout({ MetodoDePago: metodoDePago, FormaDePago: FormaDePago.DEPOSITO}));
      } else {
        await dispatch(updateCheckout({ MetodoDePago: metodoDePago, FormaDePago: FormaDePago.TARJETA }));
      }

      let carritoNew = await getShowCarritoAsync(carrito.idCarrito, metodoDePago);
      carritoNew.ItemsNoDisponibles = itemsNoDisponibles;
      await dispatch(updateCarrito(carritoNew.toJSON()));
    }
  }

  // Agregar producto al carrito
  const handleProductoAddCarritoCheCkOutAsync = async (producto, cantidad = 1) => {
    let itemsNoDisponibles =  store.getState().carrito.ItemsNoDisponibles;

    if(producto !== null && producto !== undefined && producto !== '') {
      let carritoNew = await postAddProductoCarritoAsync(store.getState().carrito.idCarrito, producto, cantidad, store.getState().checkout.MetodoDePago);
      carritoNew.ItemsNoDisponibles = itemsNoDisponibles;
      await dispatch(updateCarrito(carritoNew.toJSON()));
    }
  }

  // Actualizar Producto del Carrito
  const handleUpdateProductoCarritoCheckOutAsync = async (producto, cantidad = 1) => {
    let itemsNoDisponibles = carrito.ItemsNoDisponibles;

    if(producto !== null && producto !== undefined && producto !== '') {
      let carritoNew = await putUpdateProductoCarritoAsync(carrito.idCarrito, producto, cantidad, checkout.MetodoDePago);
      carritoNew.ItemsNoDisponibles = itemsNoDisponibles;
      await dispatch(updateCarrito(carritoNew.toJSON()));
    }
  }

  // Eliminar un producto en el carrito
  const handleDeleteProductoCarritoCheckOutAsync = async (producto) => {
    let itemsNoDisponibles = carrito.ItemsNoDisponibles;

    if(producto !== null && producto !== undefined && producto !== '') {
      let carritoNew = await deleteProductoCarritoAsync(carrito.idCarrito, producto, checkout.MetodoDePago);
      carritoNew.ItemsNoDisponibles = itemsNoDisponibles;
      await dispatch(updateCarrito(carritoNew.toJSON()));
    }
  }

  // Vacia el carrito
  const handleClearProductoCarritoCheckOutAsync = async () => {
    let carritoNew = await putLimpiarCarritoAsync(carrito.idCarrito, checkout.SucursalDeEntrega.CodigoSucursal);
    await dispatch(updateCarrito(carritoNew.toJSON()));
  }

  // Registar Direccion de envio checkOut
  const handleDireccionDeEnvioCheckOutAsync = async (direccionDto) => {

    // Registar o Actualizar Direccion
    if(direccionDto.Id === 0) {
      await postAddDireccionAsync(direccionDto);
    } else {
      await putUpdateDireccionAsync(direccionDto);
    }
    
    // Eliminar Flete Existente
    let producto = carrito.Items.find(producto => producto.Area.toUpperCase() === funcionalArea.FLETES)
    if(producto !== undefined && producto !== null) {
      await handleDeleteProductoCarritoCheckOutAsync(producto.CodigoInventario);
    };

    // Agrega nuevo Flete
    let flete = new FleteDto({
      CodigoSucuCompra:    checkout.SucursalDeEntrega.CodigoSucursal,
      DepartamentoEntrega: direccionDto.Departamento,
      MunicipioEntrega:    direccionDto.Municipio,
      ZonaEntrega:         direccionDto.Zona,
      IdCarrito:           carrito.idCarrito,
      EsMonetario:         (checkout.MetodoDePago === metodoDePago.MONETARIO)
    });

    let result = await postShowFlete(flete);
    if(result.Precio > 0) {
      await handleProductoAddCarritoCheCkOutAsync(result.Codigo, 1);
    }

    await dispatch(updateCheckout({ DireccionDeEntrega: direccionDto.toJSON(), QuienEntrega: result.Metodo }));
  }

  // Actualizar Forma de pago
  const handleUpdateFormaPago = async (pago) => {
    await dispatch(updateCheckout({ FormaDePago: pago }));
  }

  // Agregar Cupon de descuento
  const handleAddCuponCarritoCheckOutAsync = async (cupon) => {
    let itemsNoDisponibles = carrito.ItemsNoDisponibles;

    if(cupon !== null && cupon !== undefined && cupon !== '') {
      let carritoNew = await postAddCodigoDescuentoAsync(carrito.idCarrito, cupon, checkout.MetodoDePago);
      carritoNew.ItemsNoDisponibles = itemsNoDisponibles;
      await dispatch(updateCarrito(carritoNew.toJSON()));
    }
  }

  // Eliminar Cupon de descuento
  const handleDeleteCuponCarritoCheckOutAsync = async () => {
    let itemsNoDisponibles = carrito.ItemsNoDisponibles;

    let carritoNew = await deleteCodigoDescuentoAsync(carrito.idCarrito, checkout.MetodoDePago);
    carritoNew.ItemsNoDisponibles = itemsNoDisponibles;
    await dispatch(updateCarrito(carritoNew.toJSON()));
  }

  return {
    checkout, 
    carrito, 
    handleResetCarritoCheckOut, 
    handleCreateCarritoCheckOutAsync, 
    handleUpdateSucursalCarritoCheckOutAsync,
    handleProductoAddCarritoCheCkOutAsync,
    handleUpdateMetodoDePagoCarritoAsync,
    handleUpdateProductoCarritoCheckOutAsync,
    handleDeleteProductoCarritoCheckOutAsync,
    handleClearProductoCarritoCheckOutAsync,
    handleDireccionDeEnvioCheckOutAsync,
    handleUpdateFormaPago,
    handleAddCuponCarritoCheckOutAsync,
    handleDeleteCuponCarritoCheckOutAsync,
    handleUpdateSucursalProductoCheckOutAsync,
    handleGetState
  }
}

export default useCheckOutHandler;