import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";

import './../style.css';

const TablaLaboral = ({array, deleteFunc}) => {

  return (
    <div className="tabla-container-experieces">
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{textAlign: 'center'}}><strong>EMPRESA</strong></TableCell>
                        <TableCell style={{textAlign: 'center'}}><strong>PUESTO</strong></TableCell>
                        <TableCell style={{textAlign: 'center'}}><strong>FECHA INICIO</strong></TableCell>
                        <TableCell style={{textAlign: 'center'}}><strong>FECHA FIN</strong></TableCell>
                        <TableCell style={{textAlign: 'center'}}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {array.length > 0 ? (
                    array.map((fila, index) => (
                    <TableRow key={index}>
                        <TableCell style={{textAlign: 'center'}}>{fila.empresa}</TableCell>
                        <TableCell style={{textAlign: 'center'}}>{fila.puesto}</TableCell>
                        <TableCell style={{textAlign: 'center'}}>{fila.fecha_inicio}</TableCell>
                        <TableCell style={{textAlign: 'center'}}>{fila.fecha_fin}</TableCell>
                        <TableCell style={{width: '10rem', textAlign: 'center'}}>
                            <button onClick={() => {deleteFunc(fila)}}>ELIMINAR</button>
                        </TableCell>
                    </TableRow>
                    ))
                ) : (
                    <TableRow>
                        <TableCell colSpan={4} align="center">
                            Sin experiencias laborales
                        </TableCell>
                    </TableRow>
                )}
                </TableBody>
            </Table>
        </TableContainer>
    </div>
  );
};

export default TablaLaboral;