import { styled } from '@material-ui/core';
import React from 'react';
import IconExpandMore from '../icons/IconExpandMore';
import IconExpandLess from '../icons/IconExpandLess';
import { useState } from 'react';

const TextSelect = ({ label, elementos = [], ...props }) => {
    const [open, setOpen] = useState(false);

  return (
    <ContentRoot>
        <Label>{ label }</Label>
        <Select { ...props } onClick={() => setOpen(!open)}>
            { elementos.map((elemento, key) => <option key={key} value={elemento}>{elemento}</option>)}
        </Select>
        { open ?  <ExpandLess/> : <ExpandMore/> }
    </ContentRoot>
  )
}

const ContentRoot = styled('div')({
    width: '100%',
    position: 'relative',
});

const Label = styled('label')({
    position: 'absolute',
    top: '10px',
    left: '20px',
    fontSize: '8px',
    color: '#6f6f6f',
    whiteSpace: 'nowrap',
    fontWeight: '600',
    letterSpacing: '0.035em',
});

const Select = styled('select')({
    width: '100%',
    height: '60px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '20px',
    backgroundColor: '#f7f7f7',
    borderStyle: 'none',
    borderRadius: '10px',
    fontSize: '14px',
    color: '#5e5e5e',
    '-moz-appearance': 'none', 
    '-webkit-appearance': 'none',
    appearance: 'none',
    '&:focus': {
        outline: 'none',
        border: 'solid 2px #f0761d',
    },
    '& > option:checked': {
        backgroundColor: '#F0761D',
        color: 'white',
    },
    '& option': {
        backgroundColor: 'rgba(240, 118, 29, 0.02)',
    },
});

const ExpandMore = styled(IconExpandMore)({
    position: 'absolute',
    right: '20px',
    top: '20px',
    width: '28px',
    height: '28px',
    fill: '#828282',
    pointerEvents: "none",
    fontWeight: 800
});

const ExpandLess = styled(IconExpandLess)({
    position: 'absolute',
    right: '25px',
    top: '20px',
    width: '28px',
    height: '28px',
    fill: '#828282',
    pointerEvents: "none",
    fontWeight: 800
});

export default TextSelect;
