
import "./css/TextBox.css"
import useIcons from "../../shared/hooks/useIcons"

const TextBox = (props) => {

    const getIcon = useIcons()
    
    return (
        <>
            <div className="txt-intelaf-labeled" style={props.containerStyle}>
                <label htmlFor={props.id} style={props.labelStyle} >{props.Label}</label>
                <input
                    id={props.id}
                    autoComplete={props.autoComplete} 
                    required={props.required}
                    pattern={props.pattern} 
                    placeholder={props.placeholder} 
                    name={props.name}
                    onChange={props.onChange} 
                    onFocus={props.onFocus} 
                    onBlur={props.onBlur} 
                    onKeyDown={props.onKeyDown} 
                    type={props.type === undefined ? "text": props.type} 
                    value={props.valor}
                    disabled={props.disabled}
                    defaultValue={props.defaultValue}
                    maxLength={props.maxlength}
                    tabIndex={props.tabIndex ?? "-1"}
                    accept={props.accept}
                    style={props.inputStyle}
                    checked={props.checked}
                />
                {(props.icon!=null?getIcon(props.icon, "#CACACC", 25, 25):"")}
            </div>
        </>

    )
}
export default TextBox