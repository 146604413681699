
const Capitalize = (text) => {
    return text
    .split(' ')  // Separa el texto por espacios
    .map(palabra => {
        // Capitaliza la primera letra de cada palabra y convierte el resto a minúsculas
        return palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase();
    })
    .join(' ');  // Vuelve a unir las palabras con espacios
}

export default Capitalize;