import React, { useEffect, useState } from 'react';
import Button from '../../../components/controls/Button';
import TextBox from '../../../components/controls/TextBox';
import TextSelect from '../../../components/controls/TextSelect';
import TablaLaboral from './TablaLaboral';
import useExcepcion from '../../../shared/hooks/useExcepcion';
import useCatalogoReclutamiento from '../../../services/intelafApiClient/handler/useCatalogoReclutamiento';
import { empresaPuestoValidator, endDateValidator, startDateValidator } from '../../../shared/validatorsUtils';

import './../style.css';

const DatosLaborales = ({ formData, handleChange, setFormData}) => {
    const { catalogo } = useCatalogoReclutamiento();
    const [ expLab, setExpLab ] = useState({empresa: '', puesto: '', trabajo_actual: false, fecha_inicio: '', fecha_fin: ''});
    const [ arrayExp, setArrayExp ] = useState([]);
    const { handleMessageException } = useExcepcion();
    const expAmount = 3; 

    useEffect(() => {
        const updatePlaces = catalogo?.puestos;
        if (updatePlaces?.length) {
            setFormData((prev) => ({ ...prev, PlazaAplicada: updatePlaces[0] }));
            return;
        }
        setFormData((prev) => ({ ...prev, PlazaAplicada: '' }));
    }, [catalogo?.puestos]);

    const handleChangeExperienciaLab = (e) => {
        const { id, value, checked } = e.target;
        setExpLab((prev) => ({ ...prev, [id]: id === 'trabajo_actual' ? checked : value }));
        if (checked) setExpLab((prev) => ({...prev, fecha_fin: 'Actualidad'}));
    };

    const addExperienceArray = () => {
        try {
            // Validaciones
            empresaPuestoValidator(expLab?.empresa);
            empresaPuestoValidator(expLab?.puesto);
            startDateValidator(expLab?.fecha_inicio);
            endDateValidator(expLab?.fecha_fin, expLab?.fecha_inicio);
            if(arrayExp?.length >= expAmount) throw new Error('Se alcanzó el límite de experiencias laborales');
            // Mostrar en pantalla y mirar
            setArrayExp((prevArray) => [...prevArray, expLab]);
            setExpLab({empresa: '', puesto: '', trabajo_actual: false, fecha_inicio: '', fecha_fin: ''});
            // Modificar formData
            concatExperiencies([...arrayExp, expLab]);
        } catch (err) {
            handleMessageException(err);
        }
    };

    const concatExperiencies = (arr) => {
        // Limpiando valores
        for (let i = 1; i <= expAmount; i++) {
            handleChange({target: { id: `ExperienciaLaboral${i}`, value: '' }});
        }
        // Modificando valores
        arr.forEach((element, index) => {
            const concatStr = Object.entries(element).filter(([key]) => key !== "trabajo_actual").map(([key, value]) => `${key}: ${value}`).join(", ");
            handleChange({target: { id: `ExperienciaLaboral${index+1}`, value: concatStr }});
        });
    };

    const deleteExperiencia = (itemToDelete) => {
        const newArr = arrayExp.filter((item) => item !== itemToDelete)
        // Modificar array
        setArrayExp(newArr);
        // Modificar formData
        concatExperiencies(newArr);
    };

    return (      
        <div className="formulario-reclutamiento">
            <div>
                <b>Datos Laborales</b>
                <hr />
            </div>
            <div className="formulario-fila-col-2"> 
                <TextSelect
                    id='PlazaAplicada'
                    label='PLAZA DE INTERÉS **'
                    valor={formData?.PlazaAplicada}
                    onChange={handleChange}
                    elementos={catalogo?.puestos}
                    disabled={!catalogo?.puestos?.length}
                />
                <TextBox
                    id="Profesion"
                    Label="Profesión"
                    valor={formData?.Profesion}
                    onChange={handleChange}
                    tabIndex={0}
                />
            </div>
            <div style={{marginTop: '3rem'}}>
                <b>Experiencia Laboral </b> (Si no tiene, dejar en blanco)
                <hr />
            </div>
            <div className={expLab?.trabajo_actual ? 'formulario-fila-col-5' : 'formulario-fila-col-6'}>
                <TextBox
                    id="empresa"
                    Label="Empresa"
                    valor={expLab?.empresa}
                    onChange={(e) => { handleChangeExperienciaLab(e)}}
                    tabIndex={0}
                />
                <TextBox
                    id="puesto"
                    Label="Puesto de trabajo"
                    valor={expLab?.puesto}
                    onChange={(e) => { handleChangeExperienciaLab(e)}}
                    tabIndex={0}
                />
                <TextBox
                    type='checkbox'
                    id="trabajo_actual"
                    Label="¿Labora aquí actualmente?"
                    checked={expLab?.trabajo_actual}
                    onChange={(e) => { handleChangeExperienciaLab(e)}}
                    containerStyle={{display: 'flex', flexDirection: 'column', alignItems: 'center',}}
                    inputStyle={{width: '2rem', marginBottom: '0px'}}
                    tabIndex={0}
                />
                <TextBox
                    id="fecha_inicio"
                    Label="Fecha de Inicio"
                    valor={expLab?.fecha_inicio}
                    onChange={(e) => { handleChangeExperienciaLab(e)}}
                    type='date'
                    tabIndex={0}
                />
                <TextBox
                    id="fecha_fin"
                    Label="Fecha Fin"
                    valor={expLab?.fecha_fin}
                    onChange={(e) => { handleChangeExperienciaLab(e)}}
                    containerStyle={{display: expLab?.trabajo_actual ? 'none' : 'block'}}
                    type='date'
                    tabIndex={0}
                />
                <Button
                    Texto='AGREGAR'
                    className='button-add-experience'
                    onClick={addExperienceArray}
                />
            </div>
            <TablaLaboral array={arrayExp} deleteFunc={deleteExperiencia}/>
        </div>
    );
};

export default DatosLaborales;