import React from 'react';
import TextBox from '../../../components/controls/TextBox';
import TextSelect from '../../../components/controls/TextSelect';
import useCatalogoReclutamiento from '../../../services/intelafApiClient/handler/useCatalogoReclutamiento';

import './../style.css';

const DatosPersonales = ({ formData, handleChange}) => {
  const { catalogo } = useCatalogoReclutamiento();

  return (      
    <div className="formulario-reclutamiento">
        <div>
            <b>Datos Personales</b>
            <hr />
        </div>
        <div className="formulario-fila-col-1">
            <TextBox
                id="NombreCompleto"
                Label="Nombre Completo **"
                valor={formData?.NombreCompleto}
                onChange={handleChange}
                tabIndex={0}
            />
        </div>
        <div className="formulario-fila-col-2">
            <TextBox
                id="Telefono"
                Label="Teléfono"
                valor={formData?.Telefono}
                onChange={handleChange}
                type='number'
                tabIndex={0}
            />
            <TextBox
                id="CorreoElectronico"
                Label="Correo electrónico **"
                valor={formData?.CorreoElectronico}
                onChange={handleChange}
                tabIndex={0}
            />
        </div>
        <div className="formulario-fila-col-2">
            <TextBox
                id="NIT"
                Label="NIT **"
                valor={formData?.NIT}
                onChange={handleChange}
                tabIndex={0}
            />
            <TextBox
                id="DireccionActual"
                Label="Dirección actual"
                valor={formData?.DireccionActual}
                onChange={handleChange}
                tabIndex={0}
            />
        </div>
        <div className="formulario-fila-col-2">
            <TextBox
                id="FechaNacimiento"
                Label="Fecha de nacimiento **"
                valor={formData?.FechaNacimiento}
                onChange={handleChange}
                type='date'
                tabIndex={0}
            />
            <TextSelect
                id="EstadoCivil"
                label='ESTADO CIVIL'
                value={formData?.EstadoCivil}
                onChange={handleChange}
                elementos={catalogo?.estadoCivil}
                disabled={!catalogo?.estadoCivil?.length}
            />
        </div>
    </div>
  );
};

export default DatosPersonales;