import React from 'react';
import NoImage from '../../assets/productos/no-image.png';
import ModalCarrusel from './ModalCarrusel';
import ThumbnailList from './ThumbnailList';
import ImageValidation from './ImageValidation';
import ReactImageMagnify from 'react-image-magnify';
import IconArrowForwardIos from '../../../components/icons/IconArrowForwardIos';
import ArrowBackIosNewIcon from '../../../components/icons/IconArrowBackIosv2';
import { Divider } from '@material-ui/core';
import { useState, useEffect } from 'react';

import '../css/CarruselProductos.css';

const CarruselProductos = ({ images = [], codigo }) => {  
  const [onError, setOnError] = useState(false);
  const [isFading, setIsFading] = useState(false);
  const [imageSize, setImageSize] = useState({ width: 1300, height: 1300 });
  const [errorImage, setErrorImage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState({path: NoImage, preview: NoImage, thumbnail: NoImage});
  const [modalAnimation, setModalAnimation] = useState('');

  useEffect(() => {
    setOnError(!(images && images?.length > 0));
    setErrorImage(false);
    handleSetImage(images[0]);
  }, [images]);

  useEffect(() => {
    if (!isModalOpen) setModalAnimation('');
  }, [isModalOpen]);

  useEffect(() => {
    if (selectedImage.path === NoImage) return;
    const img = new Image();
    img.src = selectedImage.path;
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const baseSize = 2000;
      setImageSize( aspectRatio > 1 
        ? { width: baseSize, height: baseSize / aspectRatio }
        : { width: baseSize * aspectRatio, height: baseSize });
    };
  }, [selectedImage.path]);

  const changeImage = (direction) => {
    const currentIndex = images?.findIndex((img) => img.path === selectedImage.path);
    const newIndex = direction === 'next' ? (currentIndex + 1) % images?.length : (currentIndex - 1 + images?.length) % images?.length;
    handleSetImage(images[newIndex]);
  };

  const handleSetImage = (img) => {
    setSelectedImage({path: img?.path, preview: img?.path_preview, thumbnail: img?.path_thumbnail});
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
    setModalAnimation('fade-in');
  };

  const handleCloseModal = () => {
    setIsFading(true);
    setModalAnimation('fade-out');
    setTimeout(() => {
      setIsModalOpen(false);
      setIsFading(false);
    }, 300);
  };

  const magnifyConfig = {
    imageClassName: 'selected-modal-image',
    enlargedImageContainerStyle: {
      backgroundColor: 'white',
    },
    smallImage: {
      alt: 'Product',
      isFluidWidth: true,
      src: selectedImage.path,
    },
    largeImage: {
      src: selectedImage.path,
      width: imageSize.width,
      height: imageSize.height,
    },
    enlargedImagePosition: 'over',
    enlargedImageContainerDimensions: {
      width: "180%",
      height: "180%",
    }
  }

  return (
    onError ?
    <ImageValidation errorImage={errorImage} setErrorImage={setErrorImage} codigo={codigo}/>
    :
    <div className='carrusel-container'>
      <div className='selected-image-container no-select'>
        <img
          className='selected-image'
          fetchpriority="high"
          itemProp="image"
          src={selectedImage.preview}
          alt="Selected Product"
          onClick={handleModalOpen}
          onError={() => setOnError(true)}
          loading="eager"
        />
        <div className='prev-image-button' onClick={() => changeImage('prev')}>
          <ArrowBackIosNewIcon width={23.75} height={24} fill={'rgba(0, 0, 0, 0.15)'} />
        </div>
        <div className='next-image-button' onClick={() => changeImage('next')}>
          <IconArrowForwardIos width={23.75} height={24} fill={'rgba(0, 0, 0, 0.15)'} />
        </div>
      </div>

      <div className='thumbnails-container'>
        <ThumbnailList images={images} selectedImage={selectedImage} setSelectedImage={handleSetImage} setOnError={setOnError} />
      </div>

      <ModalCarrusel open={isModalOpen} handleCloseModal={handleCloseModal} modalAnimation={modalAnimation} isFading={isFading} >
        <div className='prev-modal-button' onClick={() => changeImage('prev')}>
          <ArrowBackIosNewIcon width={50} height={52} fill={'rgba(0, 0, 0, 0.15)'} />
        </div>
        <div className='zoom-modal-button no-select'
          style={{ 
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ReactImageMagnify {... magnifyConfig }/>
        </div>
        <div 
          className='modal-options-container'
          style={{ position: 'absolute', bottom: '0', width: '100%', marginTop: '3rem', backgroundColor: 'white', borderRadius: '20px'}}
        >
          <Divider className='modal-divider'/>
          <div className='thumbnail-modal'>
            <ThumbnailList images={images} selectedImage={selectedImage} setSelectedImage={handleSetImage} setOnError={setOnError} /> 
          </div>
        </div>
        <div className='next-modal-button' onClick={() => changeImage('next')}>
          <IconArrowForwardIos width={50} height={52} fill={'rgba(0, 0, 0, 0.15)'} />
        </div>
      </ModalCarrusel>
    </div>
  );
};

export default CarruselProductos;