import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  scroll: {
    title: '',
    position: 0,
  },
  promotions: {
    category: 0,
    preCategory: 0,
    slideIndex: 0,
  },
  grid: {
    order: 1,
  }
};

const handlerResetScroll = (state) => {
  state.scroll = initialState.scroll;
}

const handlerResetPromotions = (state) => {
  state.promotions = initialState.promotions;
}

const handlerSetScroll = (state, action) => {
  state.scroll = action.payload;
}

const handlerSetPromotions = (state, action) => {
  state.promotions = {...state.promotions ,...action.payload};
}

const handlerSetGrid = (state, action) => {
  state.grid = {...state.grid ,...action.payload};
}

const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
      setScroll: handlerSetScroll,
      setPromotions: handlerSetPromotions,
      setGrid: handlerSetGrid,
      resetScroll: handlerResetScroll,
      resetPromotions: handlerResetPromotions,
    },
});

export const generalReducer = generalSlice.reducer;
export const { setScroll, setPromotions, setGrid, resetScroll, resetPromotions } = generalSlice.actions;