import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { sucursalesReducer } from "./state/sucursalesSlice";
import { usuarioReducer } from "./state/usuarioSlice";
import { carritoReducer } from "./state/carritoSlice";
import { checkoutReducer } from "./state/checkOutSlice";
import { bannerReducer } from "./state/bannerSlice";
import { menuReducer } from "./state/menuSlice";
import { generalReducer } from "./state/generalSlice";
import { productosReducer } from "./state/productosSlice";

import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from "redux-persist/lib/storage/session";

const persistConfig = {
    key: 'root',
    storage: sessionStorage,
    whitelist: ['general', 'productos']
};

const rootReducer = combineReducers({
    sucursales: sucursalesReducer,
    usuario: usuarioReducer,
    carrito: carritoReducer,
    checkout: checkoutReducer,
    banners: bannerReducer,
    general: generalReducer,
    productos: productosReducer,
    menu: menuReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export const persistor = persistStore(store);