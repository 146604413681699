import { useState } from 'react';
import { putUsuarioReclutamientoAsync } from '../repository/usuarioRepository';
import sweetAlert from '../../../shared/SweetAlert';
import useExcepcion from '../../../shared/hooks/useExcepcion';

const useReclutamiento = () => {
    
    const [loader, setLoader] = useState(false);
    const { handleMessageException } = useExcepcion();

    const handleSendForm = async (form) => {
        try {
            setLoader(true);
            const formData = new FormData();

            for (const [key, value] of Object.entries(form)) {
                if (value) formData.append(key, value);
            }

            return putUsuarioReclutamientoAsync(formData)
            .then(async res => {
                if (res?.status === 200){
                    sweetAlert.fire({
                        title: '¡Proceso finalizado!',
                        text: 'Pronto nos pondremos en contacto contigo para los siguientes pasos.',
                        icon: 'success',
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: 'Orange',
                        focusConfirm: true,
                    }).then(async (result) => {
                        if (result?.isConfirmed) {
                            window.location.reload();
                        }
                    });
                    return true;
                }
                const msg = await res?.json();
                throw new Error( msg?.state?.Message || res?.statusText || 'Servicio no disponible');
            }).catch((err) => {
                handleMessageException(err);
            }).finally(() => {
                setLoader(false);
            });
        } catch(err) {
            handleMessageException(err);
        }
        return false;
    }
    return { loader, handleSendForm };
}

export default useReclutamiento;