import React from 'react';
import Button from '../../../components/controls/Button';
import useExcepcion from '../../../shared/hooks/useExcepcion';
import useReclutamiento from '../../../services/intelafApiClient/handler/useReclutamiento';
import {
  addressValidator, 
  birthDateValidator, 
  emojiValidator, 
  debtValidator,
  emailValidator,
  fileValidator,
  jobInterestValidator,
  licenseValidator, 
  maritalStatusValidator, 
  nameValidator, 
  nitValidator, 
  phoneValidator, 
  professionValidator, 
  salaryExpectationValidator, 
  studentValidator, 
  vehicleValidator, 
  workExperienceValidator } from '../../../shared/validatorsUtils';

import './../style.css';

const SubmitButton = ({ formData, clearFormData }) => {
  const { handleMessageException } = useExcepcion();
  const { loader, handleSendForm } = useReclutamiento();

  const validateForm = () => {
    try {
      nameValidator(formData?.NombreCompleto);
      phoneValidator(formData?.Telefono);
      emailValidator(formData?.CorreoElectronico);
      nitValidator(formData?.NIT);
      addressValidator(formData?.DireccionActual);
      emojiValidator(formData?.DireccionActual);
      birthDateValidator(formData?.FechaNacimiento);
      maritalStatusValidator(formData?.EstadoCivil);
      workExperienceValidator(formData?.ExperienciaLaboral1);
      workExperienceValidator(formData?.ExperienciaLaboral2);
      workExperienceValidator(formData?.ExperienciaLaboral3);
      emojiValidator(formData?.ExperienciaLaboral1);
      emojiValidator(formData?.ExperienciaLaboral2);
      emojiValidator(formData?.ExperienciaLaboral3);
      jobInterestValidator(formData?.PlazaAplicada);
      professionValidator(formData?.Profesion);
      debtValidator(formData?.Deuda1);
      debtValidator(formData?.Deuda2);
      debtValidator(formData?.Deuda3);
      emojiValidator(formData?.Deuda1);
      emojiValidator(formData?.Deuda2);
      emojiValidator(formData?.Deuda3);
      vehicleValidator(formData?.Vehiculo);
      licenseValidator(formData?.Licencia);
      salaryExpectationValidator(Number(formData?.PretensionSalarial));
      studentValidator(Boolean(formData?.EstudiaActualmente));
      fileValidator(formData?.cv, ['pdf']);
      return true;
    } catch (err) {
      handleMessageException(err);
    }
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      handleSendForm(formData)
      .then(res => {
        if (res) {
          clearFormData();
          window.scrollTo(0, 0);
        }
      });
    }
  };

  return (      
    <Button
        Texto="Enviar"
        className="btn-intelaf btn-intelaf-orange reclutamiento-send"
        ButtonLoading={loader}
        onClick={handleSubmit}
    />
  );
};

export default SubmitButton;