import { CircularProgress } from '@material-ui/core';
import './css/ButtonWithIcon.css';

const ButtonWithIcon = (props) => {

    const iconStyles = {
        fontSize: `${props.iconSize}px`,
        fontWeight: `${props.iconWeight}`,
        display: 'flex',
        alignItems: 'center',
    };

    return (
        <button
            className={`buttonWithIcon no-select ${props.className}`}
            onClick={props.onClick}
            disabled={props.loading}
            style={{
                height: `${props.height}px`,
                width: `${props.width}px`,
                flexDirection: `${props.flexDirection}`,
                justifyContent: `${props.justifyContent}`,
                paddingLeft: `${props.paddingLeft}px`,
                opacity: props.opacity,
                ...props?.style,
            }}
            aria-label={props.ariaLabel}
            type={props.type} 
        >
            {
                props.loading ?
                    <>
                        <CircularProgress color='inherit' size={25} />
                    </> :
                    <>
                        <span style={iconStyles}>
                            {props.icon}
                        </span>
                        <div className='separacion-icon' style={{ margin: `${props.margin}px` }}></div>
                        <span
                            className='text'
                            style={{ fontSize: `${props.textSize}px`, fontWeight: `${props.textWeight}`, marginRight: `${props.textMarginRight}` }}
                        >
                            {props.text}
                        </span>
                    </>
            }
        </button>
    )
}

export default ButtonWithIcon;