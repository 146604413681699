import React from 'react';

import '../css/CarruselProductos.css';

const ModalCarrusel = ({ open, handleCloseModal, modalAnimation, isFading, children}) => {
  return (
    open && (
      <div
        className={`fade-in ${isFading ? 'fade-out' : ''} modal-container`}
        onClick={(e) => { if (e.target === e.currentTarget) handleCloseModal()}}
      >
        <div
          className={`modal-overlay ${modalAnimation} carrusel-modal-container no-selected`}
          onClick={(e) => e.stopPropagation()}   
        >
          { children }
          <button className='close-modal-button no-selected' onClick={handleCloseModal}>
            &times;
          </button>
        </div>
      </div>
    )
  );
};

export default ModalCarrusel;