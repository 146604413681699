import React from 'react';
import Button from '../../../components/controls/Button';
import TextBox from '../../../components/controls/TextBox';
import TextSelect from '../../../components/controls/TextSelect';
import TablaDeudas from './TablaDeudas';
import useExcepcion from '../../../shared/hooks/useExcepcion';
import useCatalogoReclutamiento from '../../../services/intelafApiClient/handler/useCatalogoReclutamiento';
import { useEffect, useState } from 'react';
import { bankValidator, debtAmountValidator } from '../../../shared/validatorsUtils';

import './../style.css';

const InformacionFinanciera = ({ formData, handleChange, handleChangeCheckbox, setFormData}) => {
  const { catalogo } = useCatalogoReclutamiento();
  const [ debt, setDebt ] = useState({banco: '', deuda: ''});
  const [ arrayDebt, setArrayDebt] = useState([]);
  const { handleMessageException } = useExcepcion();
  const debtAmount = 3;

  useEffect(() => {
    const updatedOptions = getVehicleTypes(catalogo?.vehiculos, formData?.Vehiculo);
    if (updatedOptions?.length) {
      setFormData((prev) => ({ ...prev, TipoLicencia: updatedOptions[0] }));
      return;
    }
    setFormData((prev) => ({ ...prev, Licencia: false }));
  }, [formData?.Vehiculo]);

  const modifyArray = (arr) => {
    return arr?.map(item => {
      if (typeof item === "string") return item;
      else if (typeof item === "object") return Object.keys(item)[0];
    });
  };

  const getVehicleTypes = (catalogo, Vehiculo) => {
    // Si tiene licencia pero no tiene vehiculo
    if ((!Vehiculo || Vehiculo?.toLowerCase() === 'no tengo') && catalogo?.length) {
      return catalogo.reduce((acc, item) => {
        if (typeof item === "object") {
          const key = Object.keys(item)[0];
          acc.push(...item[key]);
        }
        return acc;
      }, []);
    } 
    // Si tiene vehiculo
    const found = catalogo?.find(
      item => typeof item === "object" && item[Vehiculo]
    );
    return found ? found[Vehiculo] : [];
  };

  const handleChangeDebt = (e) => {
    const { id, value } = e.target;
    setDebt((prev) => ({ ...prev, [id]: value }));
  };

  const addExperienceArray = () => {
    try {
      // Validaciones
      bankValidator(debt?.banco)
      debtAmountValidator(Number(debt?.deuda));
      if(arrayDebt?.length >= debtAmount) throw new Error('Se alcanzó el límite de deudas');
      // Mostrar en pantalla y mirar
      setArrayDebt((prevArray) => [...prevArray, debt]);
      setDebt({banco: '', deuda: ''});
      // Modificar formData
      concatDebts([...arrayDebt, debt]);
    } catch (err) {
      handleMessageException(err);
    }
  };

  const concatDebts = (arr) => {
    // Limpiando valores
    for (let i = 1; i <= debtAmount; i++) {
      handleChange({target: { id: `Deuda${i}`, value: '' }});
    }
    // Modificando valores
    arr.forEach((element, index) => {
      const concatStr = Object.entries(element).map(([key, value]) => `${key}: ${value}`).join(", ");
      handleChange({target: { id: `Deuda${index+1}`, value: concatStr }});
    });
  };

  const deleteDeuda = (itemToDelete) => {
    const newArr = arrayDebt.filter((item) => item !== itemToDelete)
    // Modificar array
    setArrayDebt(newArr);
    // Modificar formData
    concatDebts(newArr);
  };

  return (      
    <div className="formulario-reclutamiento">
        <div>
          <b>Información Financiera</b>
          <hr/>
        </div>
        <div className="formulario-fila-col-3">
          <TextSelect
            id='Vehiculo'
            label='VEHÍCULO'
            value={formData?.Vehiculo}
            onChange={handleChange}
            elementos={modifyArray(catalogo?.vehiculos)}
            disabled={!catalogo?.vehiculos?.length}
          />
          <TextSelect
            id='TipoLicencia'
            label='TIPO DE LICENCIA'
            value={formData?.TipoLicencia}
            onChange={handleChange}
            elementos={getVehicleTypes(catalogo?.vehiculos, formData?.Vehiculo)}
          />
          <TextBox
            id="Licencia"
            Label="¿LICENCIA VIGENTE?"
            valor={formData?.Licencia}
            onChange={handleChangeCheckbox}
            type='checkbox'
            containerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2rem'}}
            labelStyle={{position: 'relative', left: '0px', top: '0px', marginBottom: '0px'}}
            inputStyle={{width: '2rem', marginTop: '0px' }}
            tabIndex={0}
          />
        </div>
        <div className="formulario-fila-col-21"> 
          <TextBox
            id="PretensionSalarial"
            Label="Pretención salarial"
            valor={formData?.PretensionSalarial}
            onChange={handleChange}
            type='number'
            tabIndex={0}
          />
          <TextBox
            id="EstudiaActualmente"
            Label="¿ESTUDIA ACTUALMENTE?"
            valor={formData?.EstudiaActualmente}
            onChange={handleChangeCheckbox}
            type='checkbox'
            containerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2rem'}}
            labelStyle={{position: 'relative', left: '0px', top: '0px', marginBottom: '0px'}}
            inputStyle={{width: '2rem', marginTop: '0px' }}
            tabIndex={0}
            />
        </div>
        <div style={{marginTop: '3rem'}}>
          <b>Deudas </b> (Si no tiene, dejar en blanco)
          <hr/>
        </div>
        <div className="formulario-fila-col-3"> 
          <TextBox
            id="banco"
            Label="Banco"
            valor={debt?.banco}
            onChange={(e) => handleChangeDebt(e)}
            tabIndex={0}
          />
          <TextBox
            id="deuda"
            type='number'
            Label="Saldo Pendiente"
            valor={debt?.deuda}
            onChange={(e) => handleChangeDebt(e)}
            tabIndex={0}
          />
          <Button
            Texto='AGREGAR'
            className='button-add-experience'
            onClick={addExperienceArray}
          />
        </div>
        <TablaDeudas array={arrayDebt} deleteFunc={deleteDeuda}/>
    </div>
  );
};

export default InformacionFinanciera;