import React, { useEffect, useState } from 'react';
import {
    Typography,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ListItem
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import IconExpandMore from '../../../../icons/IconExpandMore';
import { useResponsive } from '../../../../../contexts/Responsive';
import { useNavigate } from 'react-router-dom';

const SucuGroup = ({ sucursales  }) => {
    const isMovil = useResponsive();
  

    const [isCapital, setCapital] = useState(true);
    const [isDepa,       setDepa] = useState(true);
    const [isCentro,   setCentro] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        setCapital(!isMovil)
        setDepa(!isMovil)
        setCentro(!isMovil)
    }, [isMovil])

    const handleCapitalChange = () => {
        setCapital(!isCapital);
    }

    const handleDepaChange = () => {
        setDepa(!isDepa);
    }

    const handleCentroChange = () => {
        setCentro(!isCentro);
    }

    const getSearchProducts = (nombre) => {
        navigate('/tiendas');

        setTimeout(() => {
            const element = document.getElementById('txtSearch');
            const button  = document.getElementById('btn-busqueda-tienda');

            element.value = nombre;
            button.click();
        }, 500)
    }


  return (
    <div>
        <div 
        id="footer"
        style={{
            display: 'grid',
            gridTemplateColumns: !isMovil ? '3fr 2fr 1.3fr' : '1fr',
        }}
    >
        <Accordion 
            expanded={isCapital} 
            onChange={handleCapitalChange}
            style={{ margin: '0px', boxShadow: 'none', background: 'rgb(247, 247, 247)'}}
        >
            <AccordionSummary expandIcon={ !isMovil ? <></> : <IconExpandMore  width={24} height={24} />}>
            <Title>Tiendas de la capital</Title>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block', padding: isMovil && '0px 0px 0px'}}>
                <Grid style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr'
                }}>
                    {
                        sucursales.filter(x => x.Tipo === 1).map(sucu => (
                            <ListItem button key={sucu.Nombre} onClick={() => getSearchProducts(sucu.NombreCortoSucursal)}>
                                <SubTitle>{sucu.Nombre.toCapitalize()}</SubTitle>
                            </ListItem>
                        ))
                    }
                </Grid>
            </AccordionDetails>
        </Accordion>

        <Accordion 
            expanded={isDepa} 
            onChange={handleDepaChange}
            style={{ margin: '0px', boxShadow: 'none', background: 'rgb(247, 247, 247)' }}
        >
            <AccordionSummary expandIcon={ !isMovil ? <></> : <IconExpandMore  width={24} height={24} />}>
            <Title>Tiendas departamentales</Title>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block'}}>
                <Grid style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr'
                }}>
                    {
                        sucursales.filter(x => x.Tipo === 2).map(sucu => (
                            <ListItem button key={sucu.Nombre} onClick={() => getSearchProducts(sucu.Nombre)}>
                                <SubTitle>{sucu.Nombre.toCapitalize()}</SubTitle>
                            </ListItem>
                        ))
                    }
                </Grid>
            </AccordionDetails>
        </Accordion>

        <Accordion 
            expanded={isCentro} 
            onChange={handleCentroChange}
            style={{ margin: '0px', boxShadow: 'none', background: 'rgb(247, 247, 247)'}}
        >
            <AccordionSummary expandIcon={ !isMovil ? <></> : <IconExpandMore  width={24} height={24} />}>
            <Title>Centros de servicio</Title>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block'}}>
                <Grid>
                    {
                        sucursales.filter(x => x.Tipo === 3).map(sucu => (
                            <ListItem button key={sucu.Nombre} onClick={() => getSearchProducts(sucu.Nombre)}>
                                <SubTitle>{sucu.Nombre.toCapitalize()}</SubTitle>
                            </ListItem>
                        ))
                    }
                </Grid>
            </AccordionDetails>
        </Accordion>
        </div>
        <hr/>
    </div>
  )
}

const Title = styled(Typography)({
    fontSize: '16px',
    fontWeight: 500,
    color: '#222'
});

const SubTitle = styled(Typography)({
    fontSize: '12px',
    color: '#333'
});

export default SucuGroup;