import { startTransition } from 'react';
import { useEffect, useState } from 'react';
import { getReclutamientoCatalogoAsync } from '../repository/usuarioRepository';
import useExcepcion from '../../../shared/hooks/useExcepcion';

const useCatalogoReclutamiento = () => {
    const [loader, setLoader] = useState(true);
    const [catalogo, setCatalogo] = useState(null);
    const { handleMessageException } = useExcepcion();

    useEffect(() => {
        setLoader(true);

        startTransition(() => {
            getReclutamientoCatalogoAsync()
            .then(res => {
                setCatalogo(res);
            }).catch((err) => {
                setCatalogo({});
                handleMessageException(err);
            }).finally(() => {
                setLoader(false);
            });
        });
    }, []);
    
    return { loader, catalogo };
}

export default useCatalogoReclutamiento;