import { Box, Toolbar, styled } from '@material-ui/core';
import React, { useEffect } from 'react'
import IconAccountCircle from '../../../icons/IconAccountCircle';
import InputSearch from './InputSearch';
import LinkWithIcon from './LinkWithIcon';
import IconFavorite from '../../../icons/IconFavorite';
import IconMenu from '../../../icons/IconMenu';
import ButtonCategory from './ButtonCategory';
import LinkLogoSvg from './LinkLogoSvg';
import CartBadge from './CartBadge';
import  useSearchIntelaf from '../../../../services/intelafApiClient/handler/useSearchIntelaf';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Swal from '../../../../shared/SweetAlert';

const ToolBarOne = ({ top, checkout, carrito, media, onMenu }) => {

    const [textSearch, setTextSearch] = useState ();
    
    const { 
        dataSearch, 
        loader, 
        handleGetSearchIntelaf
    } = useSearchIntelaf();

    const navigate = useNavigate();

    const handleSearchChange = (value) => {
        handleGetSearchIntelaf(value);
        setTextSearch(value);
    }

    const handleSearchClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
    
        if (!textSearch || textSearch.trim().length < 2) {
            Swal.fire({
                title: 'Lo sentimos',
                text: !textSearch 
                    ? 'Por favor, ingrese los datos en el campo de búsqueda.' 
                    : 'El valor ingresado es muy corto, por favor ingrese un valor mayor a 2 caracteres.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: 'Orange',
            });
            return;
        }
    
        navigate("/buscar?query=" + textSearch.trim().toLowerCase());
    };

    const [width, setWidth] = useState('100%');

    function handleResize() {
        const toolbar = document.getElementById('mui-tool-bar-two');
        const newWidth = toolbar.offsetWidth;
        setWidth(newWidth);
    }

    useEffect(() => {
        handleResize();
        
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };

        // eslint-disable-next-line 
    }, []); 

  return (
    <MuiToolbar
        top={top}
        width={width}
    >
        <Content media={media}>          
            <MuiBoxOne media={media}>
                <LinkLogoSvg
                    to={'/'}
                />
                {
                    media ? <></> : 
                    <ButtonCategory
                        text={'Categorías'}
                        onClick={onMenu}
                    />  
                }
            </MuiBoxOne>
            <InputSearch
                onChange={handleSearchChange}
                onClick={handleSearchClick}
                data={dataSearch}
                top={top}
                loader={loader}
                placeholder={'Buscar en Intelaf'}
            />
            <MuiBoxTwo media={media}>
                <LinkWithIcon
                    icon={<IconAccountCircle width={35} height={35} fill={'#F0761D'}/>}
                    pro={'Mi'}
                    title={'Cuenta'}
                    to={'/usuario'}
                    media={media}
                />
                <LinkWithIcon
                    icon={<IconFavorite width={35} height={35} fill={'#F0761D'}/>}
                    pro={'Mis'}
                    title={'Favoritos'}
                    to={'/usuario/favoritos'}
                    media={media}
                />
                <CartBadge
                    checkout={checkout}
                    quantity={carrito.CantidadItems} 
                />
            </MuiBoxTwo>

            {
                media ? 
                <IconMenu 
                    onClick={onMenu} 
                    style={{ gridArea: 'menu', justifySelf: 'end' }} 
                    width={35} 
                    height={35} 
                    fill={'#F0761D'}
                /> : <></>
            }
        </Content>
    </MuiToolbar>
  )
}

const MuiBoxTwo = styled(Box)(({ media = false }) => ({
    display: 'grid',
    gridTemplateColumns: media ? '1fr 1fr 1fr' : '1.5fr 1.5fr 1fr',
    alignItems: 'center',
    justifyItems: 'end',
    gridArea: 'cuenta'
}));

const MuiBoxOne = styled(Box)(({ media }) => ({
    display: 'grid',
    gridTemplateColumns: media ? '1fr' : '1fr 1fr',
    gridArea: 'logo'
}));

const Content = styled('div')(({ media }) => ({
    maxWidth: '1230px',
    minHeight: '65px',
    padding: media ? '0px 0px 10px 0px' : '0px 15px',
    margin: '0 auto',
    backgroundColor: 'white',
    display: 'grid', 
    gridTemplateAreas: media ? `"logo logo logo logo logo logo logo logo logo logo logo logo logo cuenta cuenta cuenta" 
                                "inputSearch inputSearch inputSearch inputSearch inputSearch inputSearch inputSearch inputSearch inputSearch inputSearch inputSearch inputSearch inputSearch inputSearch inputSearch menu"` :  `"logo inputSearch inputSearch inputSearch cuenta"`,
    gap: media ? '5px' : '10px',
    alignItems: 'center',
}));

const MuiToolbar = styled(Toolbar)(({ top, width }) => ({
    position: 'fixed',
    top: '0',
    width: width || '100%',
    maxWidth: '100%',
    display: 'block',
    minHeight: '65px',
    zIndex: '1200',
    margin: '0px',
    padding: '0px 15px',
    backgroundColor: 'white',
    color: 'black',
    boxShadow: top ? 'rgb(0 0 0 / 3%) 0px -1px 2px, rgb(0 0 0 / 3%) 0px 1px 2px, rgb(0 0 0 / 3%) 0px 3px 4px' : 'rgba(0, 0, 0, 0.02) 0px 1px 1px 0px inset',
}));


export default ToolBarOne;
