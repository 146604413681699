import React from 'react';
import NoImage from '../../assets/productos/no-image.png';

import '../css/CarruselProductos.css';

const ImageValidation = ({ errorImage, setErrorImage, codigo }) => {
  return (
    <div className='producto-imagen'>
      <div className='imagen no-select'>
        {
          errorImage === false
          ? 
          <img 
            fetchpriority="high"
            itemProp="image"
            width={200}
            height={146}
            onError={() => { setErrorImage(true) }}
            src={"https://www.intelaf.com/images/productos/gran/"+codigo+".jpg"} 
            alt={codigo.toLowerCase()} 
            loading="eager"
          />
          : 
          <img 
            itemprop="image" 
            src={NoImage} 
            alt="No disponible"
          />
        }
      </div>
    </div>
  );
};

export default ImageValidation;