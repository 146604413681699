import { createAdapterUsuario } from "../../../adapter/usuarioAdapter";
import { getAsync, postAsync, postNoHeaders, putAsync } from "../httpServiceIntelafApi";
import { Browser, Sistema } from '../../../shared/Info';
import { getAuthorization } from '../../../shared/TokenHeaders';

const URI = '/usuario';

export const postLoginUserAsync = (email, password) => {
    return postAsync(URI + '/login', {}, {
            Email: email,
            Password: password,
            Sesion: {
                TokeFirebase: "",
                Dispositivo: Sistema(),
                Sistema: Browser(),
                Type: 1
            }
        }
        ).then((user) => { 
        return createAdapterUsuario(user);
    });
}

export const putUpdateDataUserAsync = (user) => {
    return putAsync(URI, getAuthorization(), user).then((result) => {
        return result;
    });
}

export const putUpdateCredencialsUserAsync = (user) => {
    return putAsync(URI + '/clave', getAuthorization(), user).then((result) => {
        return result;
    });
}

export const postCreateUsuarioAsync = (user) => {
    return postAsync(URI + '/registroCaptcha', {}, user).then((response) => {
        return response;
    });
}

export const deleteUsuarioAsync = (userName) => {
    return getAsync(URI + `/eliminarCuenta?usuario=${userName}`, getAuthorization(), {}).then((response) => {
        return response; 
    })
}

export const postRestablecerUsuarioAsync = (userNewPassword)=>{
    return postAsync(URI + `/restablecer`, {}, userNewPassword).then((response) => {
        return response;
    });
}

export const getRestablecerTokenValidarAsync = (token)=>{
    return getAsync(URI + `/token/validar?token=${token}`, {}).then((response) => {
        return response;
    })
}

export const postRecuperarContraseñaAsync = (email)=>{
    return postAsync(URI + `/recuperarContra?usuario=${email}`,{}, {}).then((response) => {
        return response;
    })
}

export const getUsuarioLogoutAsync = ()=>{
    return getAsync(URI + `/logout`,getAuthorization()).then((response) => {
        return response;
    })
}

export const postValidarCuentaUsuarioAsync = (codigo) => {
    return postAsync(URI + `/validar?codigo=${codigo}`, getAuthorization(), {}).then((response) => {
        return response;
    });
}

export const postReenviarCodigoEmailAsync = (email) => {
    return postAsync(URI + `/validacion?usuario=${email}`, {}, {}).then((response) => {
        return response;
    })
}

export const getUsuarioTokenAsync = () => {
    return getAsync(URI + '/token', getAuthorization()).then((response) => {
        return createAdapterUsuario(response);
    })
}

export const putUsuarioCompletarDatosAsync = (datos) => {
    return putAsync(URI + '/completarDatos', {}, datos).then((user) => {
        return createAdapterUsuario(user);
    })
}

export const getUsuarioSesionAsync = () =>{
    return getAsync(URI + '/sesion/', getAuthorization()).then((response) => {
        return response;
    })
}

export const getUsuarioDesactivarSesionAsync = (id)=>{
    return getAsync(URI + `/sesion/desactiva?id=${id}`, getAuthorization()).then((response)=> {
        return response;
    })
}

export const putUsuarioReclutamientoAsync = (datos) => {
    return postNoHeaders('/reclutamiento/curriculum', datos).then((user) => {
        return user;
    })
}

export const getReclutamientoCatalogoAsync = (datos) => {
    return getAsync('/reclutamiento/catalogos', getAuthorization()).then((user) => {
        return user;
    })
}