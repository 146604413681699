import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: '',
  idPage: '',
  list: [],
  parameters: {
    Query : '',
    Marcas : [],
    Categorias : [],
    PrecioMenor : 0,
    Instruccion : {nombre: '', valor: ''},
    Acendente : true,
    Orden : 'default',
    CantidadMaxima : 12,
    PrecioMayor : 0,
    SucursalesCodigo : [],
  },
  filter: {
    Marcas : [],
    PrecioMenor : 0,
    Orden : 'default',
    PrecioMayor : 0,
  },
  grid: {
    Pagina: 2,
    CantidadProductos: 100,
    CantidadMaxima: 12,
    HasMore: false,
  },
  filterModal: {
    Open: false,
    Data: {},
  },
};

const handlerResetProducts = () => {
  return initialState;
};

const handlerSetTitle = (state, action) => {
  state.title = action.payload;
};

const handlerSetIdPage = (state, action) => {
  state.idPage = action.payload;
};

const handlerAddProducts = (state, action) => {
  state.list = [...state.list, ...action?.payload];
};

const handlerSetProducts = (state, action) => {
  state.list = [...action?.payload];
};

const handlerCleanProducts = (state) => {
  state.list = [];
};

const handlerSetParameters = (state, action) => {
  state.parameters = {...state.parameters, ...action.payload}
};

const handlerCleanParameters = (state) => {
  state.parameters = initialState.parameters;
};

const handlerSetFilter = (state, action) => {
  state.filter = {...state.filter, ...action.payload}
};

const handlerIncrementPage = (state) => {
  state.grid.Pagina += 1;
};

const handlerResetPage = (state) => {
  state.grid.Pagina = 2;
};

const handlerSetGrid = (state, action) => {
  state.grid = {...state.grid, ...action.payload}
};

const handlerSetOpenModal = (state, action) => {
  state.filterModal = { ...state.filterModal, Open: action.payload};
};

const productosSlice = createSlice({
    name: 'productos',
    initialState,
    reducers: {
      setTitle: handlerSetTitle,
      setIdPage: handlerSetIdPage,
      addProducts: handlerAddProducts,
      setProducts: handlerSetProducts,
      cleanProducts: handlerCleanProducts,
      setParams: handlerSetParameters,
      cleanParams: handlerCleanParameters,
      setFilters: handlerSetFilter,
      resetProducts: handlerResetProducts,
      setModalFilter: handlerSetOpenModal,
      incrementPage: handlerIncrementPage,
      resetPage: handlerResetPage,
      setGrid: handlerSetGrid,
    },
});

export const {
  setTitle,
  setIdPage,
  addProducts,
  setProducts,
  cleanProducts,
  setParams,
  cleanParams,
  setFilters,
  resetProducts,
  setModalFilter,
  incrementPage,
  resetPage,
  setGrid,
} = productosSlice.actions;
export const productosReducer = productosSlice.reducer;