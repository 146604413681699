import React from 'react'
import IconHome from '../../components/icons/IconHome';
import IconInventory from '../../components/icons/IconInventory';
import IconAccountCircle from '../../components/icons/IconAccountCircle';
import IconHomeWork from '../../components/icons/IconHomeWork';
import IconVisibilityOn from '../../components/icons/IconVisibilityOn';
import IconVisibilityOff from '../../components/icons/IconVisibilityOff';
import IconFavorite from '../../components/icons/IconFavorite';
import IconLoyalty from '../../components/icons/IconLoyalty';
import IconSettings from '../../components/icons/IconSettings';
import IconLogout from '../../components/icons/IconLogout';
import IconBlock from '../../components/icons/IconBlock';
import IconPerson from '../../components/icons/IconPerson';
import IconCall from '../../components/icons/IconCall';
import IconMail from '../../components/icons/IconMail';
import IconAccountBalance from '../../components/icons/IconAccountBalance';
import IconDone from "../../components/icons/IconDone";
import IconClose from '../../components/icons/IconClose';
import IconReceiptLong from '../../components/icons/IconReceiptLong';



const useIcons = () => {

    const getIcon = (iconName, fill = 'Black', width = 24, height = 24) => {
        switch (iconName) {
            case 'home':
                return <IconHome width={width} height={height} fill={fill} />
            case 'inventory':
                return <IconInventory width={width} height={height} fill={fill} />
            case 'account_circle':
                return <IconAccountCircle width={width} height={height} fill={fill} />
            case 'home_work':
                return <IconHomeWork width={width} height={height} fill={fill} />
            case 'visibility':
                return <IconVisibilityOn width={width} height={height} fill={fill} />
            case 'visibility_off':
                return <IconVisibilityOff width={width} height={height} fill={fill} />
            case 'favorite':
                return <IconFavorite width={width} height={height} fill={fill} />
            case 'loyalty':
                return <IconLoyalty width={width} height={height} fill={fill} />
            case 'settings':
                return <IconSettings width={width} height={height} fill={fill} />
            case 'logout':
                return <IconLogout width={width} height={height} fill={fill} />
            case 'person':
                return <IconPerson width={width} height={height} fill={fill} />
            case 'call':
                return <IconCall width={width} height={height} fill={fill} />
            case 'email':
                return <IconMail width={width} height={height} fill={fill} />
            case 'account_balance':
                return <IconAccountBalance width={width} height={height} fill={fill} />
            case 'done':
                return <IconDone width={width} height={height} fill={fill} />
            case 'close':
                return <IconClose width={width} height={height} fill={fill} />
            case 'receipt_long':
                return <IconReceiptLong width={width} height={height} fill={fill} />
            default:
                return <IconBlock width={width} height={height} fill={fill} />
        }
    }

    return getIcon;
}

export default useIcons
