import React from 'react';

const IconExpandLess = (props) => {
    return (
        <svg
            style={props.style}
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            height={props.height}
            viewBox="0 -960 960 960"
            width={props.width}
            onClick={props.onClick}
        >
            <path 
                d="M480-542.463 317.076-379.539q-8.307 8.308-20.884 8.5-12.576.192-21.268-8.5-8.693-8.692-8.693-21.076 0-12.385 8.693-21.077l179.769-179.769q5.615-5.615 11.846-7.923 6.23-2.307 13.461-2.307t13.461 2.307q6.231 2.308 11.846 7.923l179.769 179.769q8.308 8.308 8.5 20.884.193 12.577-8.5 21.269-8.692 8.692-21.076 8.692t-21.076-8.692L480-542.463Z"
                fill={props.fill}
            />
        </svg>
    );
};

export default IconExpandLess;
