class ProductoDetalleDto {
    constructor(producto) {
        this.Codigo                   = producto.Codigo                   || '';
        this.Descripcion              = producto.Descripcion              || '';
        this.PrecioNormal             = producto.PrecioNormal             || 0.0;
        this.PrecioAnterior           = producto.PrecioAnterior           || 0.0;
        this.PrecioDescuento          = producto.PrecioDescuento          || 0.0;
        this.DescripcionDescuento     = producto.DescripcionDescuento     || '';
        this.FechaCaducaDescuento     = producto.FechaCaducaDescuento     || '';
        this.Imagen                   = producto.Imagen                   || '';
        this.Existencia               = producto.Existencia               || [];
        this.ProductoImagenes         = producto.ProductoImagenes         || [];
        this.EnBodega                 = producto.EnBodega                 || false;
        this.EnTransito               = producto.EnTransito               || false;
        this.OcultarBodegaTransito    = producto.OcultarBodegaTransito    || false;
        this.AplicaPromoIndividual    = producto.AplicaPromoIndividual    || false;
        this.Marca                    = producto.Marca                    || '';
        this.EsFavorito               = producto.EsFavorito               || false;
        this.AreaFuncional            = producto.AreaFuncional            || '';
        this.AreaFuncional2            = producto.AreaFuncional2          || '';
        this.AreaFuncionalDescripcion = producto.AreaFuncionalDescripcion || '';
        this.AreaFuncional2Descripcion = producto.AreaFuncional2Descripcion || '';
        this.LinkMarca                = producto.LinkMarca                || '';
        this.FechaCreacion            = producto.FechaCreacion ? new Date(producto.FechaCreacion) : new Date();
        this.Garantia                 = producto.Garantia                 || 0
        this.MPN                      = producto.MPN                      || ''
        this.AreaFuncional            = producto.AreaFuncional            || ''
        this.ServicioToGo             = producto.AreaFuncional            || ''
        this.ServicioAutoEntrega      = producto.AreaFuncional            || ''
        this.ServicioTeLlegaExpress   = producto.AreaFuncional            || ''
        this.ServicioCargoExpreso     = producto.AreaFuncional            || ''
        this.CodigoVideo              = producto.CodigoVideo              || ''
        this.FechaTransito            = producto.FechaTransito ? new Date(producto.FechaTransito) : new Date('01/01/1900');
        this.ProveedorDetallado       = producto.ProveedorDetallado       || ''
        this.ProductosRecomendados    = producto.ProductosRecomendados    || [];
        this.ProductoImagenes         = producto.ProductoImagenes         || [];
        this.SeoDescription           = producto.SeoDescription           || null;
        this.SeoKeywords              = producto.SeoKeywords              || null;
        this.SeoTitle                 = producto.SeoTitle                 || null;
    }

    toJSON() {
        return JSON.parse(JSON.stringify({ ...this }))
    }
}

export default ProductoDetalleDto;