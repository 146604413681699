import { useState } from 'react';
import { setScroll } from '../../../../../redux/state/generalSlice';
import { setProducts } from '../../../../../redux/state/productosSlice';
import { useDispatch } from 'react-redux';
import useCheckOutHandler from '../../../../../redux/handler/useCheckOutHandler';
import Swal from '../../../../../shared/SweetAlert';

const useCheckOutService = () => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();

    const {
        checkout, 
        handleUpdateSucursalCarritoCheckOutAsync, 
        handleCreateCarritoCheckOutAsync, 
        handleResetCarritoCheckOut,
        handleUpdateSucursalProductoCheckOutAsync
    } = useCheckOutHandler();

    const handleCreateUpdateCarrito = async (sucursalDto,  metodoDeEntrega, codigo, onClose) => {
        try {
            setLoader(true);

            if(sucursalDto !== null) {
                /* Limpíando lista de productos */
                dispatch(setScroll({title: '', position: 0}));
                dispatch(setProducts([]));
                
                if(checkout.IsCarritoActivo && codigo === null) {
                    await handleUpdateSucursalCarritoCheckOutAsync(sucursalDto, metodoDeEntrega);

                } else if(checkout.IsCarritoActivo && codigo !== null) {

                    await handleUpdateSucursalProductoCheckOutAsync(sucursalDto, metodoDeEntrega, codigo);
                    Swal.fire({
                        title: '',
                        text: 'Producto añadido al carrito',
                        icon: 'success',
                        showDenyButton: true,
                        confirmButtonText: 'Ir a mi carrito',
                        confirmButtonColor: 'DarkOrange',
                        denyButtonText: "Seguir comprando",
                        denyButtonColor: "Orange"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = '/Carrito';
                        }
                    });

                } else {
                    await handleCreateCarritoCheckOutAsync(sucursalDto, metodoDeEntrega, codigo)

                    if(codigo !== null) {
                        Swal.fire({
                            title: '',
                            text: 'Producto añadido al carrito',
                            icon: 'success',
                            showDenyButton: true,
                            confirmButtonText: 'Ir a mi carrito',
                            confirmButtonColor: 'DarkOrange',
                            denyButtonText: "Seguir comprando",
                            denyButtonColor: "Orange"
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = '/Carrito';
                            }
                        });
                    }
                }
                window.scrollTo(0, 0);
                onClose(true);
            }

        } catch(err) {
            Swal.fire({
                title: 'Lo sentimos',
                text: err.message,
                icon: 'error',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: 'Orange',
            });

            onClose(false);
        } finally {
            setLoader(false);
        }
    }

    const handleOlvidarCarrito = async (onClose) => {
        Swal.fire({
            text: '¿Estás seguro de que quieres olvidar el método de entrega? Ten en cuenta que si confirmas esta acción, cualquier producto que tengas en tu carrito de compras se eliminará automáticamente.',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: 'orange',
            cancelButtonColor: 'darkorange',
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
            focusCancel: true
        }).then((result) => {
            if (result.isConfirmed) {
                handleResetCarritoCheckOut();
            }
        })

        onClose(false);
    }

  return {
    checkout,
    loader,
    handleCreateUpdateCarrito,
    handleOlvidarCarrito
  }
}

export default useCheckOutService
