class SucursalDto {
  constructor(sucursal) {
      this.Abierta                  = sucursal.Abierta                  || false;
      this.AceptaPicking            = sucursal.AceptaPicking            || false;
      this.CodigoSucursal           = sucursal.CodigoSucursal           || null;
      this.Correo                   = sucursal.Correo                   || '';
      this.Direccion                = sucursal.Direccion                || '';
      this.Direccion_corta          = sucursal.Direccion_corta          || '';
      this.Fax                      = sucursal.Fax                      || '';
      this.HorarioDescripcion1      = sucursal.HorarioDescripcion1      || '';
      this.HorarioDescripcion2      = sucursal.HorarioDescripcion2      || '';
      this.HorarioFin1              = sucursal.HorarioFin1              || '';
      this.HorarioFin2              = sucursal.HorarioFin2              || '';
      this.HorarioInicio1           = sucursal.HorarioInicio1           || '';
      this.HorarioInicio2           = sucursal.HorarioInicio2           || '';
      this.Nombre                   = sucursal.Nombre                   || '';
      this.NombreCortoSucursal      = sucursal.NombreCortoSucursal      || '';
      this.NombreImagen             = sucursal.NombreImagen             || '';
      this.NombreTiendaPorUbicacion = sucursal.NombreTiendaPorUbicacion || '';
      this.Observacion              = sucursal.Observacion              || '';
      this.ObservacionesParqueo     = sucursal.ObservacionesParqueo     || '';
      this.Orden                    = sucursal.Orden                    || 0;
      this.Parqueo                  = sucursal.Parqueo                  || false;
      this.ParqueoGratis            = sucursal.ParqueoGratis            || false;
      this.ParqueoMediaHora         = sucursal.ParqueoMediaHora         || 0;
      this.ParqueoUnaHora           = sucursal.ParqueoUnaHora           || 0;
      this.Secuencia                = sucursal.Secuencia                || 1;
      this.Telefono1                = sucursal.Telefono1                || '';
      this.Telefono2                = sucursal.Telefono2                || null;
      this.Telefono3                = sucursal.Telefono3                || null;
      this.Tipo                     = sucursal.Tipo                     || 0;
      this.UbicacionLatitud         = sucursal.UbicacionLatitud         || 0;
      this.UbicacionLongitud        = sucursal.UbicacionLongitud        || 0;
      this.UrlGoogleMaps            = sucursal.UrlGoogleMaps            || '';
      this.UrlWaze                  = sucursal.UrlWaze                  || '';
      this.TextCodSucu              = this.getTextCodigoSucu();
      this.OrdenCita                 = sucursal.OrdenCita               || false;
      this.CentroServicioTipo       = sucursal.CentroServicioTipo       ?? 0;
  }

  getTextCodigoSucu() {
    if(this.CodigoSucursal !== null)  {
      if(this.CodigoSucursal > 9) {
        return this.CodigoSucursal.toString();
      } else {
        return  '0' + this.CodigoSucursal;
      }
    }
    return '05';
  }

  toJSON() {
      return JSON.parse(JSON.stringify({ ...this }))
  }

  toString() {
    return this.Nombre +", "+ this.Direccion_corta;
  }
}

export default SucursalDto;