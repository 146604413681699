import React from "react";

const ArrowBackIosNewIcon = (props) => {
  return (
    <svg
      className={props.className || "MuiSvgIcon-root MuiSvgIcon-fontSizeMedium"}
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="ArrowBackIosNewIcon"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17.77 3.77 16 2 6 12l10 10 1.77-1.77L9.54 12z" 
        fill={props.fill}
      />
    </svg>
  );
};

export default ArrowBackIosNewIcon;