import React from 'react';
import { URL_IMG_INTELAF } from '../../../shared/Constant';

import '../css/CategoriasProductos.css';

const CategoriasProductos = ({ categorias, emptyMessage }) => {
    return (
        <div className="especificaciones">
            <div className="tablas">
                {categorias?.map((categoria, index) => (
                    <div key={index} className="tabla">
                        <div className="fila-primaria">
                            <img
                                loading="lazy"
                                alt={categoria.title} 
                                style={{maxHeight: '2.3rem'}}
                                src={`${URL_IMG_INTELAF}/${categoria?.Icono}`}
                            />
                            <span className="fila-encabezado">{categoria?.Titulo}</span>
                        </div>
                        {categoria?.Caracteristicas?.map((charac, charIndex) => (
                            <div key={charIndex} className="fila">
                                <div className={`columna-h-${charIndex % 2 === 0 ? 1 : 3}`}>
                                    {charac?.Clave}
                                </div>
                                <div className={`columna-h-${charIndex % 2 === 0 ? 2 : 4}`}>
                                    {charac?.Valor?.trim() ? charac?.Valor : emptyMessage }
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CategoriasProductos;